import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/routes/index';
import store from '@/store/index';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import vueMoment from 'vue-moment';
import customFilters from '@/utils/filters';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import notification from '@/plugins/notification';
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false;
Vue.filter('abbreviateNumber', customFilters.abbreviateNumber);
Vue.filter('toFixedFloat', customFilters.toFixedFloat);
Vue.filter('osTypeForHuman', customFilters.osTypeForHuman);
Vue.filter('convertDateFormat', customFilters.convertDateFormat);
Vue.filter('convertAdNetworkName', customFilters.convertAdNetworkName);
Vue.filter('convertUserStatus', customFilters.convertUserStatus);
Vue.filter('convertAdunitId', customFilters.convertAdunitId);
Vue.filter('hasFieldsValue', customFilters.hasFieldsValue);
Vue.filter('getCountryName', customFilters.getCountryName);
Vue.filter('getCurrencyIcon', customFilters.getCurrencyIcon);
Vue.filter('getSDKVersion', customFilters.getSDKVersion);
Vue.filter('getState', customFilters.getState);
Vue.filter('stateString', customFilters.stateString);
Vue.filter('stateDirectionClass', customFilters.stateDirectionClass);

Vue.use(vueMoment);
Vue.use(notification);
Vue.use(Toast, {
  position: POSITION.BOTTOM_CENTER,
});

new Vue({
  router,
  store,
  vuetify,
  DateRangePicker,
  render: h => h(App),
}).$mount('#app');
